import React from "react";
import { AppProps as NextAppProps } from "next/app";
import { PageProps } from "types";

//global styles
import "@wuilt/site-style/css/index.css";
import "@wuilt/section-preview/style.css";
import "@wuilt/sections-designs/style.css";
// import "@wuilt/fluid-engine/style.css";
import "../styles/globals.css";
import "line-awesome/dist/font-awesome-line-awesome/css/all.min.css";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import { IntlProvider } from "react-intl";
import BodyCustomCode from "@/components/BodyCustomCode";
import HeadCustomCode from "@/components/HeadCustomCode";

const ErrorBoundary = React.Fragment;

type AppProps<P = any> = {
  pageProps: P;
  Component: any;
} & Omit<NextAppProps<P>, "pageProps">;

function SiteApp({ Component, pageProps }: AppProps<PageProps>) {
  const customCodes = pageProps.customCodes;
  return (
    <ErrorBoundary>
      {/* Dummy react-intl provider to probably compile @wuilt/section-elements */}
      <IntlProvider locale="en">
        <HeadCustomCode customCodes={customCodes} />
        <Component {...pageProps} />
        <BodyCustomCode customCodes={customCodes} />
      </IntlProvider>
    </ErrorBoundary>
  );
}

export default SiteApp;
